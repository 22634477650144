.dialog-content-container{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.dialog-row{
    display: flex;
    column-gap: 5%;
}
.dialog-row>textarea{
    width: 100%;
}
.dialog-row>textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: dimgray;
    opacity: 1; /* Firefox */
}
.treatment-image-modal{
    display: flex;
    justify-content: center;
    align-items: center;
}