.home-page-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
    align-items: center;
}

.home-image-caption {
    position: absolute;
    top: 25%;
    text-align: left;
    left: 10%;
    color: #fff;
}

.home-image-caption-title1 {
    margin-bottom: 0;
}

.home-image-caption-title2 {
    margin-top: 0;
}

.owl-green {
    background-color: var(--primary-color);
}

.Booking-button {
    background-color: var(--primary-color);
    border: none;
    height: 54px !important;
    border-radius: 100px;
    color: #fff;
    padding: 0 20px 0 20px
}

.lead-description {
    display: flex;
    width: 40%;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: 5% 0 5% 0;
    row-gap: 30px;
}

.description-item {
    flex: 1;
    width: 100%
}

.lead-description-title {
    color: var(--second-color);
    font-family: var(--second-font);
}

.Aditional-Services {
    display: flex;
    flex-direction: column;
    background-image: url('../../assets/images/Group 2.svg');
    height: 498px;
    width: 100%;
    color: #fff;
    align-items: center;
    justify-content: center;
    row-gap: 40px;
}

.treatments-container {
    display: flex;
    flex-direction: column;
    padding: 5% 0;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    row-gap: 20px;
    background-color: #F5F5F5;
}
.treatments-container>h1{
    color: var(--second-color);
    font-family: var(--second-font);
}
.treatments-options-container {
    display: flex;
    flex-wrap: wrap;
    width: 1340px;
    max-width: 75%;
    margin: 0 auto;
    column-gap: 20px;
    row-gap: 80px;
    align-items: center;
}

.services-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}

.service {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}

.lead-details {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5%;
    padding: 7% 0 7% 0;
}

.detail-image {
    flex: 1;
}
.detail-image>img{
    max-width: 90%;
}

.details-table {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
    justify-content: center;

    row-gap: 40px;
}
.source-pro{
    font-family: var(--second-font)
}
.detail-row {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
}

.detail-title {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.detail-description {
    flex: 3;
}

.last-detail {
    display: flex;
    column-gap: 4%;
}

.horizental-line {
    border-bottom: 1px solid #CCCCCC;
    width: 100%;
}

.image-layer{
    overflow: hidden;
    background-color: var(--third-color)
}
.image-layer>img{
    opacity:0.5
}

@media only screen and (max-width:1440px) {
    .lead-details{
        flex-direction: column;
        row-gap: 20px;
    }
    .Aditional-Services {
        height: fit-content;
        padding-top: 10%;
        padding-bottom: 10%;
    }
    .Booking-button{
        height: 34px!important;
        width: fit-content;
    }
    .ar.home-page-container .global-container.lead-details{
        flex-direction: column;
    }
}

@media(max-width:1025px){
    .Booking-button{
        max-width:unset;
    }
    .service {
        flex-wrap: wrap;
        max-width: 60%;
        align-items: center;
        justify-content: center;
        row-gap: 40px;
    }
}

@media(max-width:767px){
    .lead-details .detail-row{
      flex-direction: column;
      text-align: center;
    }

    .lead-description{width:80%;}
}