.treatment-option{
    min-height: 9rem;
    width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}
.treatmentForward{
    color: var(--second-color);
    align-items: center;
    display: flex;
    column-gap: 3%;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.go-To{
    cursor: pointer;
}
.pointer{
    cursor: pointer;
}
@media only screen and (max-width: 1420px) and (min-width:767px){
    .treatment-option{
        width: 30% ;
    }
}
@media only screen and (max-width: 767px) and (min-width:560px){
    .treatment-option{
        width: 47% ;
    }
}
@media only screen and (max-width:560px){
    .treatment-option{
        width: 100% ;
    }
}
