.green-carousel{
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 7% 0 7% 0;
}
.carousel-caption{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    row-gap: 30px;
    color: #fff;
}
.caption-icon{
    width: max-content !important;
}
.caption-signiture{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
}
.carousel-caption-title{
    font-family: var(--second-font);
}

.ar .carousel-caption-title{
    font-family: var(--third-font);
}