.procedure{
    display: flex;
    flex-direction: row;
}
.procedure-border{
    border: 2px solid var(--second-color);
    height: 235px;
    width: 235px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.procedure-background{
    background-color: var(--second-color);
    height: 220px;
    width: 220px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.procedure-background>div{
    width: 90%;
}
.procedure-number{
    background-color: var(--primary-color);
    height: 50px;
    width: 50px;
    color: #fff;
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10%;
    left: 3%;
}
.union-line{
    position: relative;
    width: 30px;
}
.union-line>hr{
    position: absolute;
    top: 50%;
    width: 100%;
    background-color: var(--primary-color);
    border: none;
    height: 2px;
}

@media only screen and (max-width:1462px){
    .procedure-border{
        width:200px;
        height:200px;
    }

    .procedure-background{
        width:180px;
        height:180px;
    }

    .procedure-number{
        height:45px;
        width:45px;
    }

    .procedure-background{font-size:16px;}
}

@media only screen and (max-width:1280px){
    .procedure-border{
        width:180px;
        height:180px;
    }

    .procedure-background{
        width:160px;
        height:160px;
    }

    .procedure-number{
        height:40px;
        width:40px;
    }

    .procedure-background{font-size:16px;}
}

@media only screen and (max-width:767px){
    .union-line{
        transform: rotate(90deg);
    }
    .procedure{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .union-line{
        height: 30px;
    }
}