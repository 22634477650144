.treatment-page-container, .package-estimator {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding: 7% 0 7% 0;
}

.package-estimator {
    padding: 0;
}

.procedures-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.treatment-image {
    width: 100%;
}

.grey-back {
    background-color: #F5F5F5;
}

.title {
    font-family: var(--second-font);
    color: var(--second-color);
}

.treat-combo-box {
    width: 100%;
    display: flex;
}

.treatments-contact-input {
    border: none;
    background-color: transparent;
    color: #000;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: flex-start;
    width: 50%;
}

.contact-input option {
    background-color: #fff;
}

.medical-offers, .offers {
    width: 100%;
}

.medical-offers {
    text-align: start;
}

.offers {
    border-collapse: collapse;
}

td {
    padding: 1%;
}

.start-row {
    border-bottom: 0 !important;
}

.middle-row {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.last-row {
    border-top: 0 !important;
}

.radio-button {
    width: 5%;
    border: 1px solid #000;
    text-align: center;
}

.offer-description {
    width: 80%;
    border: 1px solid #000;
    /* border-right: 0; */
}

.offer-cost {
    border: 1px solid #000;
    border-left: 0;
    width: 15%;
    background-color: var(--second-color);
    color: #fff;
    text-align: center;
}

.hospitals-and-doctors {
    text-align: left;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 100%;
}

.hospitals-and-doctors>p {
    color: var(--second-color);
}

.treatment-additional-services {
    display: flex;
    width: 100%;
    height: 87px;
    align-items: center;
    text-align: left;
    background-color: #F5F5F5;
}

.treatment-additional-services>form {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    width: 100%;
    column-gap: 10%;
}

.total-price {
    height: 117px;
    color: #fff;
    background-color: var(--second-color);
    display: flex;
    align-items: center;
    width: 100%;
}

.total-price>div {
    width: 100%;
    text-align: right;
    margin-right: 20px;
}

.confirm {
    width: 100%;
    display: flex;
}

.request-button {
    flex: 1;
    display: flex;
    justify-content: flex-end;

}

.italic {
    font-style: italic;
}

.request-button>button {
    padding: 10px;
    border-radius: 100px;
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;

    width: 300px !important;

}

@media only screen and (max-width:767px) {
    .procedures-container {
        flex-wrap: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .confirm {
        flex-direction: column;
    }

    .request-button {
        justify-content: center;
    }

    .request-button>button {
        width: 70%
    }

    .offers .radio-button {
        padding: 5px;
    }

    .offers .offer-description {
        padding: 10px;
    }
}