@media only screen and (max-width:767px){.MuiDataGrid-row{
    flex-direction: column;
    width: 100% !important;
    border-bottom: 1px solid #000;
    padding: 2%
}
.MuiDataGrid-virtualScrollerRenderZone{
    width: 100% !important;
}
.MuiDataGrid-virtualScroller{
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.MuiDataGrid-cell{
    content: attr(data-label);
    width: 100%;
    flex:1;
    max-width: 100% !important;
    border-bottom: 0 !important;
}
.MuiDataGrid-cellContent{
    width: 100%;
    text-align: center;
}
.MuiDataGrid-columnHeaders{
        display: none !important;
}
}
.MuiDataGrid-toolbarContainer>button{
    display: none !important;
}
.Buttontest-container{
    display: flex;
    justify-content: right;
    width: 100%;
}
.Buttontest-container>button{
    width: 30px;
    height: 30px;
    margin-bottom: 2px;
}
.Title-container{
    display: flex;
    color: var(--second-color);
    font-family: var(--second-font);
    width: 100%;
}
.Title-container>div{
    flex: 1;
    display: flex;
    align-items: center;
}
.Title-container>div>button{
    width: 80px;
    height: 34px;
    border-radius: 100px;
    background-color: var(--second-color);
    border: 0;
    color: #fff;
}
.MuiDataGrid-cell>div>button{
    width: 34px;
    height: 34px;
    border-radius: 100px;
    background-color: var(--second-color);
    border: 0;
    color: #fff;
}