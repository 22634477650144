.Buttontest-container{
    display: flex;
    justify-content: right;
    width: 100%;
}
.Buttontest-container>button{
    width: 30px;
    height: 30px;
    margin-bottom: 2px;
}
.Title-container{
    display: flex;
    color: var(--second-color);
    font-family: var(--second-font);
    width: 100%;
}
.Title-container>div{
    flex: 1;
    display: flex;
    align-items: center;
}
.Title-container>div>button{
    width: 80px;
    height: 34px;
    border-radius: 100px;
    background-color: var(--second-color);
    border: 0;
    color: #fff;
}
.admin-treat-icon{
    padding: 7%;
}
.dr-image{
    height: 190px;
}
.file-button{
    width: 30px;
    height: 30px;
    margin-bottom: 2px;
}