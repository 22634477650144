.ar.home-image-caption,
.ar.home-page-container .lead-description>p,
.ar.home-page-container .global-container.lead-details .detail-row>div{
    text-align: right;
}
.ar.home-page-container .lead-description-title,
.ar.home-page-container .source-pro,
.ar.home-page-container .treat-titles,
.ar.home-image-caption .Booking-button{
    font-family: var(--third-font) !important;
}
.ar.home-page-container .global-container.lead-description>h2 {
    text-align: end;
}
.ar.home-page-container .treatments-container .treatments-options-container,
.ar.home-page-container .Aditional-Services .services-icon-container .service,
.ar.home-page-container .global-container.lead-details,
.ar.home-page-container .global-container.lead-details .detail-row{
    flex-direction: row-reverse;
}

.ar .description-item, .home-image-caption-title1{
    direction: rtl;
}

@media only screen and (max-width:1440px) {
    .ar.home-page-container .global-container.lead-details{
        flex-direction: column;
    }
}

@media(max-width:767px){
    .ar.home-page-container .global-container.lead-details .detail-row{
      flex-direction: column;
      text-align: center;
    }
    .ar.home-page-container .global-container.lead-details .detail-row>div{
        text-align: center;
    }
}