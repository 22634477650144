.ar.global-container.treatment-page-container .procedures-container,
.ar.global-container.treatment-page-container .procedures-container .procedure,
.ar.global-container.treatment-page-container .treat-combo-box{
    flex-direction: row-reverse;
}

.ar.global-container.treatment-page-container .medical-offers .offers,
.ar.global-container.treatment-page-container .hospitals-and-doctors .hospital-container .hospital,
.ar.global-container.treatment-page-container .treatment-additional-services,
.ar.global-container.treatment-page-container .confirm{
    direction: rtl;
}

.ar.global-container.treatment-page-container .total-price>div{
    text-align: left;
    margin-left: 20px;
}

.ar.global-container.treatment-page-container .title,
.ar.global-container.treatment-page-container #treatment-title,
.ar.global-container.treatment-page-container .hospitals-and-doctors>p,
.ar.global-container.treatment-page-container .request-button>button{
    font-family: var(--third-font) !important;
}

.ar.global-container.treatment-page-container .medical-offers>p,
.ar.global-container.treatment-page-container .hospitals-and-doctors>p{
    text-align: right;
}

@media only screen and (max-width:767px){
    .ar.global-container.treatment-page-container .confirm{
        flex-direction: column;
    }
    .ar.global-container.treatment-page-container .total-price>div{
        text-align: right;
    }
    .ar.global-container.treatment-page-container .procedures-container .procedure{
        flex-direction: column;
    }
}