.logIn-container {
    margin-top: 10%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.logIn-container>div{
    border: 1px solid transparent;
    padding: 2%;
    
  box-shadow: 0px 0px 15px 5px #888888;
    border-radius: 50px;
}