body {
  margin: 0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
html {
  scroll-behavior: smooth;
}
.expandableIcon:hover{
  transform: scale(1.25);
}
.justifyText{
  text-align: justify !important;

}
.pointer{cursor: pointer;}
.owl-nav{display:none !important;}
.owl-dots{
display: none;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ar{
  font-family: var(--third-font) !important;
}
:root {
  --primary-color: #1B877B;
  --second-color: #069AD4;
  --third-color:rgb(43, 150, 114);
  --primary-font: 'Open Sans', sans-serif;
  --second-font: 'Source Sans Pro', sans-serif;
  --third-font: 'Tajawal', sans-serif;
}
.m-0{
  margin: 0px !important;
}
.primaryColor{
  color: var( --primary-color) !important;
}
.MuiTooltip-tooltip{
  background-color: var(--primary-color) !important;
  /* width: 150px !important; */
  text-align: center !important;
  padding:  15px !important;
  font-size: 16px !important;
}	
.global-container{
  width: 1440px;
  max-width: 90%;
  align-items: center;
  margin: 0 auto;
}
.header-container,.logo-container {
  display: flex;
  flex-direction: row; 
  height: 133px;
}
.logo-container{
  width: 100%;
  max-width: 90%;
  align-items: center;
}

.logo {
  flex: 1;
  align-items: center;
}

.logo {
  text-align: left ;
}
.menu-conainer{
  align-items: center;
  text-align: right;
}
.header-icon{
  display: none;
}

.menu-list{
  list-style-type: none;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  column-gap: 5px;
}

.menu-item  {
  flex: 1;
  height: 133px;
  width: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.translation-button {
  background-color: var(--primary-color);
  border: none;
  height: 28px !important;
  width: 112px !important;
  border-radius: 100px;
  color: #fff;
}

.header-active-item {
  background-color: var(--second-color);
  color: #fff;
}

.footer-container {
  height: 332px;
  color: #fff;
  display: flex;
  flex-direction: row;
  text-align: left;
}
.footer-background{
  background-color: #333333;
  width: 100%;
  position: relative;
}
.whatsapp{
  position: absolute;
  right: 2%;
  bottom: 7%;
  cursor: pointer;
}
.footer-element{
  flex: 1;
  height:60%;
  display: flex;
  align-items: center;
}
.vertical-line{
  border-right: 1px solid #fff;
  height: 60%;
}
.middle-footer{
  justify-content: center;
}
.footer-list {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.middle-footer li {
  text-align: left;
}

button{
  cursor: pointer;
}
.page-image{
  display: flex;
}

.fs18{
  font-size: 18px;
}
.fs16{
  font-size: 16px;
}
.fs74{
  font-size: 74px;
  font-size: 3.5vw;
}
.fs38{
  font-size: 38px;
}
.fs20{
  font-size: 20px;
}
.fs22{
  font-size: 22px;
}
.fs32{
  font-size: 32px;
}
.Bold{
  font-weight: bold;
}

.ar .header-container{
  flex-direction: row-reverse ;
}
.ar .logo-container{
  flex-direction: row-reverse ;
}

.ar.logo-container .logo{
  text-align: right;
}
.ar.logo-container .logo{
  text-align: right;
}

#header #menu li{
  cursor:pointer;
}

.middle-footer li a{
  color:#fff;
  text-decoration: none;
}
.ar .menu-list, .ar .footer-container{
  flex-direction: row-reverse;
}

.ar .footer-element{
  text-align: right;
}

.ar .footer-list{
  padding-left:0px;
  padding-right:15px;
}

.ar .middle-footer li{
  text-align: right;
}

.ar .footer-element:not(.middle-footer){
  justify-content: flex-end;
}

.ar .footer-element.end-footer{
  justify-content: flex-start;
}

.footer-element i{cursor:pointer;}
.footer-list li a {color:#fff; text-decoration: none;}

@media only screen and (max-width: 1025px){
  .collapse{
    display: none;
  }
  .header-container{
    display: flex;
    flex-direction: column !important;
    margin: 0 auto;
    align-items: center;
    height: 100%;
  }
  .logo-container{
    width: 100%;
  }
  .header-icon{
    display: contents;
    flex: 1;
  }
  .menu-conainer{
    width: 100%;
  }
  .menu-list{
    flex-direction: column;
    width: 100%;
    row-gap: 5px;
    padding: 0;
  }
  .menu-item{
    width: 100%;
    height: 100px;
  }
  .footer-container, .ar .footer-container{
    flex-direction: column;
    height: 100%;
  }
  .footer-background{
    height: 100%;
  }
  .vertical-line{
    border-right: 0;
    border-bottom: 1px solid #fff;
    width: 100%;
  }
  .footer-element{
    width: 100%;
  }
  .middle-footer{
    justify-content: left;
  }
  .ar .middle-footer{
    justify-content: right;
  }
  .footer-list{
    padding: 0;
  }
  .ar .footer-element.end-footer{
    justify-content: flex-end;
  }
  .fs22{
    font-size:16px;
  }
  .fs18{
    font-size: 15px;
    line-height: 22px;
  }
  .fs38{
    font-size:32px;
  }
  .ar .menu-list{
    flex-direction: column;
  }
}