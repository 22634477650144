.hospital-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.hospital-number {
    margin-left: 20px;
    height: 52px;
    width: 52px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: var(--primary-color);
}

.hospital {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    text-align: left;
    width: 100%;
    color: #fff;
    background-color: var(--primary-color);
    height: 84px;
}

.arrow-icon {
    display: flex;
    /* justify-self: end; */
    flex: 1;
    margin-right: 20px;
    margin-left: 20px;
    justify-content: flex-end;
}

.doctors-list {
    display: flex;
    flex-direction: row;
    width: 90%;
    padding: 3% 0 1% 0;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 30px;
}

.doctor {
    width: 30%;
}

.start {
    text-align: left;
}

.middle {
    text-align: center;
}

.end {
    text-align: right;
}

.ar .hospital-number {
    margin-left: 0px;
    margin-right: 20px;
}