.ar.partner-area.global-container .partner-name,
.ar.partner-area.global-container .partner-details {
    text-align: right;
}

.ar.partner-area.global-container .partner-details>ul {
    direction: rtl
}

.ar.partner-area.global-container .partner-website {
    align-self: flex-start;
}

.ar.partner-area.global-container .partner-name {
    flex-direction: row-reverse;
}

.ar.partner-area.global-container .partner-name .partner-website {
    justify-content: flex-start;
}

@media(max-width:767px) {
    .ar.partner-area.global-container .partner-name {
        flex-direction: column;
    }

    .ar.partner-area.global-container .partner-name .partner-website {
        justify-content: center;
    }

    .ar.partner-area.global-container .partner-name {
        text-align: center;
    }
}