.ar.contact-page-container .contact-forms .contact-us .contact-us-container.global-container {
    flex-direction: row-reverse;
}

.ar.contact-page-container .contact-forms .reach-us .reach-us-infos,
.ar.contact-page-container .contact-forms .reach-us .raech-us-title,
.ar.contact-page-container .contact-forms .contact-us .contact-row .contact-input,
.ar.contact-page-container .contact-forms .contact-us-title,
.ar.contact-page-container .contact-forms .contact-us .contact-us-container.global-container .contact-us-title {
    text-align: right !important;
    font-family: var(--third-font) !important;
}
.ar.contact-page-container .submit-button>button{
    font-family: var(--third-font);
}
.ar.contact-page-container .contact-forms{
    flex-direction: row-reverse;
    text-align: right;
}

@media only screen and (max-width: 1023px) {
    .ar.contact-page-container .contact-forms,
    .ar.contact-page-container .reach-us.global-container,
    .ar.contact-page-container .contact-us .contact-us-container.global-container {
        flex-direction: column;
    }
}