.service-icon-border{
    border-radius: 50%;
    border: 2px solid #fff;
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.additional-service{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;
}