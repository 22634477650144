.drs-container {
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: center;
}

.hospitalPicture {
    display: flex;
    justify-content: center;
    gap: 2%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.hospitalPicture>img {
    width: 32%;
    object-fit: contain;
}

.dr-card {
    width: 30%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.dr-card>div>img {
    width: 70%;
}

.dr-specialty {
    color: var(--second-color);
}

.partner-area {
    display: flex;
    flex-direction: column;
    align-items: unset;
    padding: 7% 0 4% 0;
}

.partner-name, .partner-details {
    text-align: left;
}

.partner-name {
    display: flex;
    width: 100%;
    font-family: var(--second-font);
    color: var(--second-color);
}

.partner-details>ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    list-style: circle;
}

.partner-details>ul>li::marker {
    color: var(--primary-color);
    font-size: 20px;
}

.partner-website {
    align-self: end;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex: 1;
}

.partner-website>button {
    width: 194px;
    height: 54px;
    border-radius: 100px;
    background-color: var(--primary-color);
    border: none;
    color: #fff;
    font-family: var(--third-font);
}

.background-color {
    background-color: #F5F5F5;
    width: 100%;
}

@media(max-width:767px) {
    .partner-details>ul>li::marker {
        font-size: 16px;
    }

    .global-container {
        max-width: 80%;
    }

    .partner-area .partner-name {
        flex-direction: column;
        text-align: center;
    }

    .partner-area .partner-website {
        justify-content: center;
        margin-top: 10px;
    }

    .dr-card {
        width: 100%;
    }
}