.contact-page-container,
.reach-us-infos {
    display: flex;
    flex-direction: column;
}

.reach-us,
.contact-us-container {
    display: flex;
    flex-direction: column;
    column-gap: 150px;
    row-gap: 30px;
    padding: 70px 0 70px 0;
}
.reach-us{
    background-color: #F5F5F5;
    padding-left: 3%;
    padding-right: 3%;
    align-self: stretch;
}
.reach-us-infos{
    row-gap: 10px;
}
.contact-forms{
    display: flex;
    text-align: left;
    align-items: flex-start !important;
    column-gap: 7%;
}
.contact-file{
    border-bottom: 1px solid #000;
    width: 100%;
    display: none;
}
.contact-file::-webkit-file-upload-button {
    display: none;
}
.contact-file-label{
    border-bottom: 1px solid #000;
    width: 100%;
}
.reach-us-infos {
    text-align: left;
    flex: 3;
}

.reach-us-infos>ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 0;
}

.reach-us-infos li a{
    color:#000;
    text-decoration: none;
}

.map-button>button {
    height: 54px;
    width: 197px;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 100px;
    border: none;
}

.raech-us-title {
    color: var(--second-color);
    font-family: var(--second-font);
    width: 100%;
    text-align: left;
}

.contact-us-title {
    color: var(--second-color);
    font-family: var(--second-font);
    flex: 1;
}

.contact-us {
    width: 100%;
    color: #000;
}

.contact-form-container {
    flex: 3;
    width: 100%;
}

.contact-form {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    width: 100%;
}

.contact-row {
    display: flex;
    column-gap: 10%;
    width: 100%;
}

.contact-input {
    border: none;
    background-color: transparent;
    color: #000;
    border-bottom: 1px solid #000;
    flex: 1;
    width: 100%;
    font-family: var(--primary-color);
}

.contact-input option {
    background-color: var(--second-color);
}

.contact-input::-webkit-input-placeholder {
    color: #000;
    font-size: 18px;
}

.contact-image {
    width: 100%;
}
.error,.error::placeholder{
    color:red
}

@media only screen and (max-width: 1023px) and (min-width:767px){
    .contact-row {
        flex-direction: column;
        row-gap: 50px;
    }

    .contact-us-container {
        flex-direction: column;
    }

    .reach-us {
        flex-direction: column;
    }
}
.mapouter{position:relative;text-align:right;width:100%;}
.gmap_canvas {overflow:hidden;background:none!important;width:100%;}
@media only screen and (max-width: 767px) {
    .contact-row {
        flex-direction: column;
        row-gap: 50px;
    }

    .contact-us-container {
        flex-direction: column;
    }

    .reach-us {
        flex-direction: column;
    }
    .contact-forms{
        flex-direction: column;
    }
}

.submit-button {
    display: flex;
    justify-content: space-between;
}

.submit-button>button {
    background-color: var(--primary-color);
    border: none;
    height: 54px !important;
    width: 165px !important;
    border-radius: 100px;
    color: #fff;
}